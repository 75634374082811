import type { TSearchState } from '../helpers/providers/search/search-provider';
import { formatDisplayDateWithYear } from '../helpers/utils/client';
import type { HomepageShopQuery } from '@dx-ui/queries-dx-property-ui';

export type useGetHomepagePricingProps = {
  language: string;
  pricingData: HomepageShopQuery | undefined;
  searchState: TSearchState | null;
};

export const useGetHomepagePricing = ({
  searchState,
  pricingData,
  language,
}: useGetHomepagePricingProps) => {
  const rate = pricingData?.hotel?.shopAvail?.summary.lowest?.rateAmountFmt;
  const formattedDateRange =
    searchState?.dates?.arrivalDate && searchState?.dates?.departureDate
      ? formatDisplayDateWithYear(
          searchState?.dates?.arrivalDate,
          searchState?.dates?.departureDate,
          language
        )
      : '';

  const availabilityStatus = pricingData?.hotel?.shopAvail?.summary.status?.type;

  const feeTransparencyInfo = pricingData?.hotel?.shopAvail?.summary?.lowest?.serviceChargeDetails;

  return { rate, dateString: formattedDateRange, availabilityStatus, feeTransparencyInfo };
};
