import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import cx from 'classnames';
import type * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { CONDUCTRICS_GOALS } from '../../helpers/constants';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { Image } from '../image/Image';
import type { TDynamicGridItemAndAspectRatios } from './dynamic-grid.types';
import { useTheme } from '../../hooks/use-theme';

export const DynamicGridLink: React.FC<TDynamicGridItemAndAspectRatios> = function (item) {
  const { isTailored } = useGlobalConfig();
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const { theme } = useTheme();
  const [imgLoaded, setImgLoaded] = useState(false);
  const { id } = item;
  const ar = item?.imageAspectRatio;
  const imageUrl = useMemo(
    () => getAspectRatioUrl({ src: item?.imageUrl, width: rect?.width ?? 0 }).twoXimgUrl || '',
    [item?.imageUrl, rect?.width]
  );

  return item ? (
    <a
      href={`${item.url}${id}`}
      key={id}
      className={cx(
        'snap group w-64 snap-start overflow-y-hidden sm:w-auto',
        {
          'md:w-1/3': item?.singular,
          'rounded-lg border border-border-alt': !isTailored,
        },
        theme.base
      )}
      data-testid="dynamicGridItemLink"
      onClick={async () => {
        await sendRewardAsync(`g-RoomCategory-${id}`);
        await sendRewardAsync(CONDUCTRICS_GOALS.ROOMS_AND_SUITES_ENGAGEMENT);
      }}
    >
      <div className="group relative sm:overflow-hidden">
        <div
          className={cx('absolute flex size-full items-end', {
            'z-2': imgLoaded,
          })}
        />
        <div ref={ref} className={cx({ 'duration-300 md:group-hover:scale-125': isTailored })}>
          <Image
            className={cx({
              'md:group-hover:opacity-50 duration-300 ease-in-out': isTailored,
            })}
            alt={item.imageAltText}
            src={imageUrl}
            width={rect?.width ?? 0}
            aspectRatio={ar}
            onLoad={() => setImgLoaded(true)}
          />
        </div>
      </div>
      <div
        className={cx(
          'w-full p-3 font-bold capitalize leading-tight group-hover:underline group-focus:underline',
          {
            'group-hover:text-accent group-focus:text-accent': theme.accent?.hover,
            'text-center text-2xl': isTailored,
            'text-left text-xl': !isTailored,
          }
        )}
      >
        {item.caption}
      </div>
    </a>
  ) : null;
};
